$(document).ready(function() {

    $(window).on('scroll', function(event) {
        let scroll = $(window).scrollTop();

        // if (scroll >= 0) {
        //     $("body").addClass("scrolling");
        // }
        // if (scroll == 0){
        //     $("body").removeClass("scrolling");
        // }
        
        if (scroll > 100) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });

    $("#back-to-top").on('click', function(event) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

});