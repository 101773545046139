jQuery(document).ready(function() {
    document.addEventListener("DOMContentLoaded",function(){
        
    const newsWrapper = document.querySelector(".news-listing-page-listing")
    const pagination = document.querySelector(".pagination-wrapper")
    const items = Array.from(document.querySelectorAll(".news-card"))
    let filteredItems = items;
    let currPage = 1;

 

    const searchFilter = () => {
        const selectedCategory = document.querySelector('#list1').value; //dropdown value
        const search           = document.getElementById('searchbyname').value; //searchbox
      
        if (search && selectedCategory) {

            filteredItems = items.filter(item => {
                return  item.dataset.category === selectedCategory && item.querySelector('.recent-news-title').innerText.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.dataset.category === selectedCategory && item.querySelector('.recent-news-content').innerText.toLowerCase().indexOf(search.toLowerCase()) > -1
            })
        
        } else if (!search && selectedCategory) {
            filteredItems = items.filter(item => {
                return item.dataset.category === selectedCategory
            })
        } else if (search && !selectedCategory) {
            filteredItems = items.filter(item => {
                return item.querySelector('.recent-news-title').innerText.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.querySelector('.recent-news-content').innerText.toLowerCase().indexOf(search.toLowerCase()) > -1
            })
        } else {
            filteredItems = items
        }
        currPage = 1;
        if (filteredItems.length !== 0) {
            pagination.style.display = "flex";
            setHTML(filteredItems)
        } else {
            pagination.style.display = "none";
            newsWrapper.innerHTML = "<h2>No Data Found.</h2>"
        }
    
    }

    function paginate(totalItems, currentPage = 1, pageSize = 2, maxPages = 10) {

        let totalPages = Math.ceil(totalItems / pageSize);
        if (currentPage < 1) {
          currentPage = 1;
        } else if (currentPage > totalPages) {
          currentPage = totalPages;
        }
      
        let startPage, endPage;
        if (totalPages <= maxPages) {
          startPage = 1;
          endPage = totalPages;
        } else {
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
            startPage = 1;
            endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
          }
        }
      
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
      
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
        };
 
    }

    function setHTML(items) {
      
        newsWrapper.innerHTML = ""
        pagination.innerHTML = ""
   
   
    const { totalItems, currentPage, pageSize, totalPages, startPage, endPage, startIndex, endIndex, pages } = paginate(items.length, currPage, 4)
    console.log(currentPage)
    // const nav = document.createElement("nav")
    const ul  = document.createElement("ul")
    //nav.classList.add(...['pagination-wrapper', 'container' ])
    ul.classList.add(...['pagination'])
    
    let paginationHTML = ""
    paginationHTML += `<li ${currentPage === 1 && 'disabled'} class="${currentPage === 1 ? 'd-none' : 'prev'} page-item">
            <span class="sr-only">Previous</span>    
            <span class="page-link-previous page-link-skip page-link fas fa-chevron-left"></span>
    </li>`
    pages.forEach(page => {
        if (currentPage === page) {
        paginationHTML += `<li class="page-item ${currentPage === page && 'active'}"><a class="page-link" aria-label="Page ${page}" page="${page}">${page}</a></li>`
        } else {
        paginationHTML += `<li class="page page-item" page="${page}"><a class="page-link" aria-label="Page ${page}" page="${page}">${page}</a></li>`
        }
    })

    paginationHTML += `<li ${currentPage === endPage && 'disabled'} class="${currentPage === endPage ? 'd-none' : 'next'} page-item">
    <span class="sr-only">Next</span>
    <span class="page-link-next page-link-skip page-link fas fa-chevron-right"></span>
    </li>`

 

        ul.innerHTML = paginationHTML
        pagination.append(ul)

        const start = (currentPage - 1) * pageSize, end = currentPage * pageSize;
        items.slice(start, end).forEach(el => {
            newsWrapper.append(el)
        })
    }


  
    document.addEventListener('click', function (e) {
       // console.log(e.target.closest(".prev") )
    const $this = e.target
    // console.log($this)
    if ($this.classList.contains("page-link")) {
        currPage = parseInt($this.getAttribute("page"))
        setHTML(filteredItems)
    }
    if ($this.classList.contains("next")) {
        currPage++;
        setHTML(filteredItems)
    }
    if( $this.classList.contains("prev")) { 
        currPage--;
        setHTML(filteredItems)
    }
  
    });

    const search = document.getElementById('searchbyname');
    const filter = document.getElementById('list1');
    let typingTimer;
    let typeInterval = 500;

    if (search ) {
        search.addEventListener("keyup", () => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(searchFilter, typeInterval)
        });
    }

    if( filter ) {
        filter.addEventListener("change", searchFilter)
    }
    setHTML(filteredItems)
 
    
 


    $('.news-search').each(function () {
        currPage = 1;
        const imageUrl = '../assets/images/submit-arrow-mobile.svg';
        const input = $(this).find("#searchbyname"),
        background = input.css('background-image', 'url("' + imageUrl + '")'),
        clearButton = $(this).find(".clearable__clear");
      
        //background.css('background-image', 'url("' + imageUrl + '")')
        input.on('input', function() {
          clearButton.toggle(!!this.value)
          background.css('background-image', 'none')
      
        })
        
       
        clearButton.on('touchstart click', function() {
            input.val('').trigger('input')
            background.css('background-image', 'url("' + imageUrl + '")')
            searchFilter();
          })
           
    })
})
});