if (1) {
  let dataVar = $('#main-filter-content').attr('data-program-src');
  $.getJSON(dataVar, {
      format: "json"
  }).done(function( response ) {

  const data = response;
  var length = response.length;
  $('.category').html('<span class="cat-title found">Found <span>' + length + '</span> Programs</span>');
   
  sortTitles(data);

const resultsContainer = document.getElementById('results-container');
const paginationContainer = document.getElementById('pagination-container');
const searchInput = document.getElementById('search-input');
const searchBtn = document.getElementById('search-btn');

let currentPage = 1;
const resultsPerPage = 12;
let currentResults = [];
let areas = [];
let programs = [];
let locations = [];

//sorting of titles A-Z 
function sortTitles( results ) {
  
  results.sort(function(a, b) {
    if( a.title < b.title ) {
     return -1;
    } 
    if( a.title > b.title ) {
       return 1
    }

    return 0;

   })
}
// Function to display search results
function displayResults(results) {


    
  resultsContainer.innerHTML = '';

  results.forEach(result => {
    const resultElem = document.createElement('div');
    resultElem.setAttribute('class', 'col-md-6');
    var department_link = result.department_link.replace(/\s+/g, "");
    // console.log(department_link);
    let title = result.title;

 
    var link = '<a href="'+department_link+'"><span class="visually-hidden">continue reading</span>&gt;&gt;</a>'; 
    
     
    resultElem.innerHTML = `
    <div class="program-listings-card">
    <h4 class="program-listings-title"><a href="#">${result.title}</a></h4>
    <p class="program-listings-degree-type"><span>Area of Interest:</span> ${result.interest}</p>
    <p class="program-listings-area-of-study"><span>Program Location: </span>${result.area}</p>
    <p class="program-listings-outcome"><span>Outcome: </span>${result.outcome}</p>
    <div class="more-info">
        <p class="more-less-collapse">
            <a class="toggle-more collapsed" data-bs-toggle="collapse" href="#expand${result.id}" role="button" aria-expanded="false" aria-controls="expand${result.id}">
                Info
            </a>
        </p>
        <div class="collapse" id="expand${result.id}">
            <div class="card card-body">
              <p>${result.description}</p>
            </div>
        </div>
    </div>
</div>
    `;
    resultsContainer.appendChild(resultElem);
  });

 
   // Initialize masonry
var grid = new Masonry(resultsContainer, {
  itemSelector: '.col-md-6',
  percentPosition: true
});
$('#results-container').masonry('reloadItems');
$('#results-container').masonry('layout');

}

function displayPagination(totalResults) {
  const totalPages = Math.ceil(totalResults / resultsPerPage);
  paginationContainer.innerHTML = '';

  if( totalResults === 0 ) {
    return;
  }
  
  // Move  direct First Page  
  const firstPage = document.createElement('li');
  firstPage.setAttribute('class', 'page-item');
  firstPage.innerHTML = '<a aria-label="Page 1" class="page-link page-link-skip page-link-previous "><span class="fas fa-chevron-left"></span></a>';
  if (currentPage === 1) {
    firstPage.classList.add('d-none');
  }
  firstPage.addEventListener('click', () => {
    currentPage = 1;
    updateResults();
  });
  paginationContainer.appendChild(firstPage);
  // Add "Previous" button
  const prevPageLink = document.createElement('li');
  prevPageLink.setAttribute('class', 'page-item');
  prevPageLink.innerHTML = '<a class="page-link page-link-previous prev" title="Previous Page" aria-label="Previous Page"><span class="fas fa-chevron-left"></span></a>';
  if (currentPage === 1) {
    prevPageLink.classList.add('d-none');
  }
  prevPageLink.addEventListener('click', () => {
    currentPage--;
    updateResults();
  });
  paginationContainer.appendChild(prevPageLink);

    // Add first page link
  const firstPageLink = document.createElement('li');
  firstPageLink.setAttribute('class', 'page-item');
  firstPageLink.innerHTML = '<a aria-label="Page 1" class="page-link">1</a>';
  if (currentPage === 1) {
    firstPageLink.classList.add('active');
  }
  firstPageLink.addEventListener('click', () => {
    currentPage = 1;
    updateResults();
  });
  paginationContainer.appendChild(firstPageLink);

  // Add ellipsis if more than 5 pages away from start
  // if (currentPage >= 5) {
  //   const dots = document.createElement('li');
  //   dots.setAttribute('class', 'page-item disabled');
  //   dots.innerHTML = '<a class="page-link">...</a>';
  //   paginationContainer.appendChild(dots);
  // }

  // Add 3 page links before and after current page
  for (let i = currentPage - 1; i <= currentPage + 1; i++) {
    if (i > 1 && i < totalPages) {
       const pageLink = document.createElement('li');
      pageLink.setAttribute('class', 'page-item');
      pageLink.innerHTML = '<a aria-label="Page ' + i + '" class="page-link">' + i + '</a>';
      if (i === currentPage) {
        pageLink.classList.add('active');
      }
      pageLink.addEventListener('click', () => {
        currentPage = i;
        console.log(currentPage)

        updateResults();
      });
      paginationContainer.appendChild(pageLink);
    }
  }

  // Add ellipsis if more than 5 pages away from end
  if (currentPage <= totalPages - 3) {
    const dots = document.createElement('li');
    dots.setAttribute('class', 'page-item disabled');
    dots.innerHTML = '<a class="page-link">...</a>';
    paginationContainer.appendChild(dots);
  }

  // Add last page link
  const lastPageLink = document.createElement('li');
  lastPageLink.setAttribute('class', 'page-item');
  lastPageLink.innerHTML = '<a aria-label="Page ' + totalPages + '" class="page-link">' + totalPages + '</a>';
  if (currentPage === totalPages) {
    lastPageLink.classList.add('d-none');
  }
  lastPageLink.addEventListener('click', () => {
    currentPage = totalPages;
    updateResults();
  });
  paginationContainer.appendChild(lastPageLink);

  // Add "Next" button
  const nextPageLink = document.createElement('li');
  nextPageLink.setAttribute('class', 'page-item');
  nextPageLink.innerHTML = '<a class="page-link  page-link-next next" title="Next Page" aria-label="Next Page"><span class="fas fa-chevron-right"></span></a>';
  if (currentPage === totalPages) {

    nextPageLink.classList.add('d-none');

  }
  nextPageLink.addEventListener('click', () => {
    currentPage++;
    updateResults();
  });
    paginationContainer.appendChild(nextPageLink);

    // Add last page link
    const lastPage = document.createElement('li');
    lastPage.setAttribute('class', 'page-item');
    lastPage.innerHTML = '<a aria-label="Page ' + totalPages + '" class="page-link page-link-skip page-link-next"><span class="fas fa-chevron-right"></span></a>';
    if (currentPage === totalPages) {
      lastPage.classList.add('d-none');
    }
    lastPage.addEventListener('click', () => {
      currentPage = totalPages;
      updateResults();
    });
    paginationContainer.appendChild(lastPage);


    }




// Function to update results based on current page and search query
function updateResults() {
  var searchQuery = searchInput.value.toLowerCase();
  var area = [];
  const degree = [];
  $('.checkbox').each(function(){
  if (this.checked == true){
   degree.push(this.value.toLowerCase().trim()); //programs
    
  } 
  });  
  $('.checkboxArea').each(function(){
  if (this.checked == true){
      area.push(this.value.toLowerCase().trim());
  
   } 

 
  });  

  console.log(degree)
 

  const filteredResults = data.filter(result => {
if (searchQuery) {
  return (
    result.title.toLowerCase().includes(searchQuery) ||
    result.program.toLowerCase().includes(searchQuery) ||
    result.areas.some(str => str.toLowerCase().includes(searchQuery))
  );
} else if (degree && degree.length > 0 && (area < 0  || area.length == 0)) {
  return degree.some(deg =>
    result.program.toLowerCase().includes(deg.toLowerCase())
  );
}else if(area &&  area.length > 0 &&  (degree.length < 0 || degree.length  == 0)){
  return area.some(area1 =>
    result.areas.some(search => search.trim().toLowerCase().includes(area1))
  );
}else if((area && area.length > 0) &&  (degree && degree.length > 0)){
 return area.some(area1 =>
    result.areas.some(search => search.trim().toLowerCase().includes(area1)) &&
    degree.some(deg =>
        result.program.toLowerCase().includes(deg.toLowerCase())
  )
  );

} else {
  return (
    result.title.toLowerCase().includes(searchQuery) ||
    result.program.toLowerCase().includes(searchQuery) ||
    result.areas.some(str => str.toLowerCase().includes(searchQuery))
  );
}
});


 
 

    currentResults = filteredResults.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);
  displayResults(currentResults);
  sortTitles(currentResults);
  displayPagination(filteredResults.length);
  
  var  len1 = filteredResults.length;
  $('.category').html('<span class="cat-title found">Found <span>' + len1 + '</span> Programs</span>');
  $('.checkbox').each(function(){
    if (this.checked == true){
      var valll =this.value;
      var ddd = valll.replace(/\s/g, "");
     var  html = '';
     html = `<a onClick="MyFunction('${ddd}');" class="clear-program"><span class="filter-result-item ${ddd}">${valll} </span></a>`;
      $('.category').append(html);
  
    }
    });
    $('.checkboxArea').each(function(){
      if (this.checked == true){
        var valll =this.value;
        var ddd = valll.replace(/\s/g, "");
       var  html = '';
       html = `&nbsp;&nbsp;<a style="margin-left: 05px;" onClick="MyFunction('${ddd}');" class="clear-program"><span class="filter-result-item ${ddd}">${valll} </span></a>`;
        $('.category').append(html);
    
      }
      });
}//update

const pushbarFilter = new Pushbar({
  blur:true,
  overlay:true,
});
$('.checkbox').on('click', function() {
  $('#search-input').val('');
});
$('.checkboxArea').on('click', function() {
  $('#search-input').val('');
});

$('.apply').on('click', function() {
  currentPage = 1;
  updateResults();

});

$('.mobile-apply').on('click', function () {
  currentPage = 1;
  updateResults();
  pushbarFilter.close();
})

// Initial results display
updateResults();


// Add event listener to search button
searchBtn.addEventListener('click', () => {
  $('.custom-control-input').prop('checked', false);
  currentPage = 1;
  updateResults();
});


function myKeyDownHandler(event) {
  if (window.matchMedia("(max-width: 1405px)").matches) {
    document.removeEventListener("keydown",myKeyDownHandler);
  }
  searchInput.style.backgroundImage = null;
  if (event.keyCode === 13) {
    event.preventDefault();
    searchBtn.click();
  }
}
document.addEventListener("keydown", myKeyDownHandler);

// Add event listener to search input for live search
searchInput.addEventListener('input', () => {
  var textv =  $('#search-input').val();
  if(textv == ''){
    currentPage = 1;
    updateResults();
  }

});

 
 
$('.form-control-wrap--search').each( function () {
  const imageUrl = '../assets/images/submit-arrow-mobile.svg';
  const input = $(this).find("input:text"),
  background = input.css('background-image', 'url("' + imageUrl + '")'),
  clearButton = $(this).find(".clearable__clear");

  //background.css('background-image', 'url("' + imageUrl + '")')
  input.on('input', function() {
    clearButton.toggle(!!this.value)
    background.css('background-image', 'none')

  })
  

  clearButton.on('touchstart click', function( e) {
    e.preventDefault();
    input.val("").trigger('input')

    background.css('background-image', 'url("' + imageUrl + '")')


  })
})
 
 

searchInput.addEventListener('checkbox', () => {
  currentPage = 1;
  updateResults();
});

$('.category').on('click',function() {
  $('.clear-program').each(function(){

      currentPage = 1;
      updateResults();
    
  });
});
$('.clear-program-filters').on('click', function() {
  $('#search-input').val('');
  $('.custom-control-input').prop('checked', false);
  currentPage = 1;
  updateResults();
});
});

}
