import $ from "jquery";

$(document).ready(function() {


    // $(".mobile-menu-hamburger").on("click", function () {
    //     $(".mobile-menu").stop().slideToggle('fast', function() {
    //         $('.hamburger--3dy').toggleClass('is-active', $(this).is(':visible'));
    //         $('body').toggleClass('mobile-menu-open', $(this).is(':visible'));
    //         if ($(this).is(':visible'))
    //         $(this).css('display','flex');
    //       });
    //       $(".hamburgercontent").not(".mobile-menu").slideUp('fast', function() {
            
    //       });
    //     return false;
    // });





    updateDivsMargins();
    $(window).resize(updateDivsMargins);
    
    function updateDivsMargins() {
        $('div.mobile-menu').each(function () {
            $(this).css({
                'margin-top': ($('.header-wrap').height()),
            });
        });
    }


    //   var myAlert = document.getElementById('alert-warning-id')
    //   myAlert.addEventListener('closed.bs.alert', function () {
    //     $(window).trigger('resize');
    //   })
  });
  