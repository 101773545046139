// ====================================
// VisionPoint Marketing
// Accordions JS
// ====================================
(function($) {
    //did this quick and dirty.. Something wrong with wp-accordions move on from package.


    $('.wf-accordion .tabs-panel').addClass('is-hidden');
    $('.accordion-trigger > .content').addClass('is-hidden')

    $('.tabs-panel').on('click', function(e) {
        e.preventDefault();
        let accordion = $(this);
        let accordionContent = accordion.find('.content');
        accordion.toggleClass('is-open is-hidden').slideDown(250)
      
     })

     $('.expand-all-btn').on('click', function(e) {
        e.preventDefault();
        let openAmount = $('.wf-accordion').find('.js-tabs-panel.is-open').length;
        let closedAmount = $('.wf-accordion').find('.js-tabs-panel').length - openAmount;     
        if( closedAmount == 0) {
             //Close them
             $('.wf-accordion').find('.js-tabs-panel').removeClass('is-open');
             $('.wf-accordion').find('.content').removeClass('is-open');
             $('.wf-accordion').find('.js-tabs-panel').addClass('is-hidden');
             $('.wf-accordion').find('.content').addClass('is-hidden');
        }else{
            $('.wf-accordion').find('.js-tabs-panel').addClass('is-open');
            $('.wf-accordion').find('.content').addClass('is-open');
            $('.wf-accordion').find('.js-tabs-panel').removeClass('is-hidden');
            $('.wf-accordion').find('.content').removeClass('is-hidden');
        }
    
     })

    
})(jQuery);    