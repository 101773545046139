// Create a clone of the menu, right next to original.
//$('.header-center-white').addClass('original').clone().insertAfter('.header-center-white').addClass('cloned').css('position','fixed').css('top','0').css('margin-top','0').css('width','100%').css('background','white').css('z-index','999').removeClass('original').hide();

// scrollIntervalID = setInterval(stickIt, 10);


// function stickIt() {

//   var orgElementPos = $('.original').offset();
//   orgElementTop = orgElementPos.top;               

//   if ($(window).scrollTop() >= (orgElementTop)) {
//     // scrolled past the original position; now only show the cloned, sticky element.

//     // Cloned element should always have same left position and width as original element.     
//     orgElement = $('.original');
//     coordsOrgElement = orgElement.offset();
//     leftOrgElement = coordsOrgElement.left;  
//     widthOrgElement = orgElement.css('width');
//     $('.cloned').css('left',leftOrgElement+'px').css('top',0).css('width',widthOrgElement).show();
//     $('.original').css('visibility','hidden');
//   } else {
//     // not scrolled past the menu; only show the original menu.
//     $('.cloned').hide();
//     $('.original').css('visibility','visible');
//   }
// }


let scrollPOS = window.scrollY; //scroll
let header = document.querySelector('.header--main'); //getting header
let headerHeight = header.offsetHeight + 100; //header height adding 100 to it 182

//add class when it hits 182
function add_class_on_scroll() {
  header.classList.add("fixed");
}
//remove class at 0
function remove_class_on_scroll() {
  header.classList.remove("fixed");
}

//scroll event
window.addEventListener('scroll', function() {
  scrollPOS = window.scrollY;//reinit
  if( scrollPOS >= headerHeight ) { //scroll is greater or equal to headerHeight
     add_class_on_scroll() //add class if so
  }else{
    remove_class_on_scroll() //remove class if no
  }
})

//js library for pushbar drawer menu
const pushbar = new Pushbar({ 
  blur:true,
  overlay:true,
});

 