jQuery(document).ready(function() {
    (function ($) {

        var $masonry = $('.program-listings-wrap');
        $masonry.masonry({
            itemSelector: '.col-md-6'
        }).on('shown.bs.collapse hidden.bs.collapse', function() {
            $masonry.masonry();
        });
        
    })(jQuery);
});

